<template>
  <div class="w-full max-w-screen-md mx-auto pt-20 mb-16 px-3">
    <h2 class="text-3xl font-semibold py-3">
      graduated.io Terms of Service
    </h2>

    <p class="py-3">
      By using the graduated.io ("Service"), you are agreeing to be bound by the
      following terms and conditions ("Terms of Service").
    </p>
    <p class="py-3">
      graduated.io ("Company") reserves the right to update and change these
      Terms of Service without notice.
    </p>
    <p class="py-3">
      Violation of any of the terms below may result in the termination of your
      account.
    </p>
    <h3 class="text-xl py-3">
      Account Terms
    </h3>
    <ol class="list-decimal list-inside pb-3">
      <li>
        You are responsible for maintaining the security of your account and
        password. The Company cannot and will not be liable for any loss or
        damage from your failure to comply with this security obligation.
      </li>
      <li>
        You are responsible for all content posted and activity that occurs
        under your account (even when content is posted by others who have their
        own logins under your account).
      </li>
      <li>
        You may not use the Service for any illegal purpose or to violate any
        laws in your jurisdiction (including but not limited to copyright laws).
      </li>
      <li>
        You must provide your legal full name, a valid email address, and any
        other information requested in order to complete the signup process.
      </li>
      <li>
        Your login may only be used by one person – a single login shared by
        multiple people is not permitted.
      </li>
      <li>
        You may create separate logins for as many people as you'd like. You
        must be a human. Accounts registered by “bots” or other automated
        methods are not permitted.
      </li>
    </ol>
    <h3 class="text-xl py-3">
      API Terms
    </h3>
    <p class="py-3">
      Customers may access their Service data via the Application Program
      Interface ("API"). Any use of the API, including use of the API through a
      third-party product that accesses the Service, is bound by the terms of
      this agreement plus the following specific terms:
    </p>
    <ol class="list-decimal list-inside pb-3">
      <li>
        You expressly understand and agree that the Company shall not be liable
        for any damages or losses resulting from your use of the API or
        third-party products that access data via the API.
      </li>
      <li>
        Abuse or excessively frequent requests to the Service via the API may
        result in the temporary or permanent suspension of your account's access
        to the API. The Company, in its sole discretion, will determine abuse or
        excessive usage of the API. The Company will make a reasonable attempt
        via email to warn the account owner prior to suspension.
      </li>
    </ol>
    <h3 class="text-xl py-3">
      Payment, Refunds, Upgrading and Downgrading Terms
    </h3>
    <ol class="list-decimal list-inside pb-3">
      <li>
        The Service is offered free to candidates and at cost to companies who
        are actively recruiting graduates, companies will only be able to
        continue using the Service by paying their subscription fee and any
        other fees associated with their chosen products. If you fail to pay for
        your services, your account will be frozen and inaccessible until
        payments are made.
      </li>
      <li>
        For any upgrade or downgrade in plan level, will result in the new rate
        being charged at the next billing cycle. There will be no prorating for
        downgrades in between billing cycles.
      </li>
      <li>
        Downgrading your Service may cause the loss of features or capacity of
        your account. The Company does not accept any liability for such loss.
      </li>
      <li>
        All fees are exclusive of all taxes, levies, or duties imposed by taxing
        authorities, and you shall be responsible for payment of all such taxes,
        levies, or duties, excluding only Australia (federal or state) taxes.
        Where required, The Company will collect those taxes on behalf of taxing
        authority and remit those taxes to taxing authorities.
      </li>
      <li>Refunds are not applicable.</li>
    </ol>
    <h3 class="text-xl py-3">
      Cancellation and Termination
    </h3>
    <ol class="list-decimal list-inside pb-3">
      <li>
        You are solely responsible for properly cancelling your account. An
        email or phone request to cancel your account is not considered
        cancellation. You can cancel your account at any time by clicking on the
        Account settings link in the global navigation bar at the top of the
        screen. The Account screen provides a simple no-questions-asked
        cancellation link.
      </li>
      <li>
        All of your content will be inaccessible from the Service immediately
        upon cancellation. Within 30 days, all content will be permanently
        deleted from active systems and logs. Within 60 days, all content will
        be permanently deleted from our backups. This information can not be
        recovered once it has been permanently deleted.
      </li>
      <li>
        If you cancel the Service before the end of your current paid up month,
        your cancellation will take effect immediately, and you will not be
        charged again. But there will not be any prorating of unused time in the
        last billing cycle.
      </li>
      <li>
        The Company, in its sole discretion, has the right to suspend or
        terminate your account and refuse any and all current or future use of
        the Service for any reason at any time. Such termination of the Service
        will result in the deactivation or deletion of your Account or your
        access to your Account, and the forfeiture and relinquishment of all
        content in your account. The Company reserves the right to refuse
        service to anyone for any reason at any time.
      </li>
    </ol>
    <h3 class="text-xl py-3">
      Modifications to the Service and Prices
    </h3>
    <ol class="list-decimal list-inside pb-3">
      <li>
        The Company reserves the right at any time and from time to time to
        modify or discontinue, temporarily or permanently, any part of the
        Service with or without notice.
      </li>
      <li>
        Prices of all Services are subject to change upon 30 days notice from
        us. Such notice may be provided at any time by posting the changes to
        the Company web site or the Service itself.
      </li>
      <li>
        The Company shall not be liable to you or to any third party for any
        modification, price change, suspension or discontinuance of the Service.
      </li>
    </ol>
    <h3 class="text-xl py-3">
      Copyright and Content Ownership
    </h3>
    <ol class="list-decimal list-inside pb-3">
      <li>
        All content posted on the Service must comply with AU copyright law.
      </li>
      <li>
        We claim no intellectual property rights over the material you provide
        to the Service. All materials uploaded remain yours.
      </li>
      <li>
        The Company does not pre-screen content, but reserves the right (but not
        the obligation) in their sole discretion to refuse or remove any content
        that is available via the Service.
      </li>
      <li>The look and feel of the Service is copyright© to the Company.</li>
      <li>
        All rights reserved. You may not duplicate, copy, or reuse any portion
        of the HTML, CSS, JavaScript, or visual design elements without express
        written permission from the Company.
      </li>
    </ol>
    <h3 class="text-xl py-3">
      General Conditions
    </h3>
    <ol class="list-decimal list-inside pb-3">
      <li>
        Your use of the Service is at your sole risk. The service is provided on
        an “as is” and “as available” basis.
      </li>
      <li>Technical support is only provided via email.</li>
      <li>
        You understand that the Company uses third party vendors and hosting
        partners to provide the necessary hardware, software, networking,
        storage, and related technology required to run the Service.
      </li>
      <li>
        You must not modify, adapt or hack the Service. You must not modify
        another website so as to falsely imply that it is associated with the
        Service or the Company.
      </li>
      <li>
        You agree not to reproduce, duplicate, copy, sell, resell or exploit any
        portion of the Service, use of the Service, or access to the Service
        without the express written permission by the Company.
      </li>
      <li>
        We may, but have no obligation to, remove content and accounts that we
        determine in our sole discretion are unlawful or violates any party’s
        intellectual property or these Terms of Service.
      </li>
      <li>
        Verbal, physical, written or other abuse (including threats of abuse or
        retribution) of any Service customer, Company employee or officer will
        result in immediate account termination.
      </li>
      <li>
        You understand that the technical processing and transmission of the
        Service, including your content, may be transferred unencrypted and
        involve (a) transmissions over various networks; and (b) changes to
        conform and adapt to technical requirements of connecting networks or
        devices.
      </li>
      <li>
        We reserve the right to temporarily disable your account if your usage
        significantly exceeds the average usage of other Service customers. Of
        course, we'll reach out to the account owner before taking any action
        except in rare cases where the level of use may negatively impact the
        performance of the Service for other customers.
      </li>
      <li>
        The Company does not warrant that (i) the service will meet your
        specific requirements, (ii) the service will be uninterrupted, timely,
        secure, or error-free, (iii) the results that may be obtained from the
        use of the service will be accurate or reliable, (iv) the quality of any
        products, services, information, or other material purchased or obtained
        by you through the service will meet your expectations, and (v) any
        errors in the Service will be corrected.
      </li>
      <li>
        You expressly understand and agree that the Company shall not be liable
        for any direct, indirect, incidental, special, consequential or
        exemplary damages, including but not limited to, damages for loss of
        profits, goodwill, use, data or other intangible losses (even if the
        Company has been advised of the possibility of such damages), resulting
        from: (i) the use or the inability to use the service; (ii) the cost of
        procurement of substitute goods and services resulting from any goods,
        data, information or services purchased or obtained or messages received
        or transactions entered into through or from the service; (iii)
        unauthorized access to or alteration of your transmissions or data; (iv)
        statements or conduct of any third party on the service; (v) or any
        other matter relating to the service.
      </li>
      <li>
        The failure of the Company to exercise or enforce any right or provision
        of the Terms of Service shall not constitute a waiver of such right or
        provision. The Terms of Service constitutes the entire agreement between
        you and the Company and govern your use of the Service, superseding any
        prior agreements between you and the Company (including, but not limited
        to, any prior versions of the Terms of Service). Questions about the
        Terms of Service should be sent to
        <span class="font-medium">support@graduated.io.</span>
      </li>
    </ol>
    <p class="py-3">
      Any new features that augment or enhance the current Service, including
      the release of new tools and resources, shall be subject to the Terms of
      Service. Continued use of the Service after any such changes shall
      constitute your consent to such changes.
    </p>
  </div>
</template>

<script>
export default {
  name: "Terms",
};
</script>
